import React from 'react';
import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';
import Servicesnum from '../components/Servicesnum';
import Development from '../components/Development';
import Languages from '../components/Languages';
import Devstage from '../components/Devstage';



const Home = () => {
    return (
        <>
            <Hero />
            <Servicesnum />
            <Services />
            <Intro />  
            <Portfolio />
            <Development />
            <Languages />
            <Devstage />
            <Clients />
            <Cta/>
            <Footer />
        </>

    )
}

export default Home;

