import React from 'react';

const Devstage = () => {
  return (
    <>
<div className="container flex flex-col items-center mx-auto ">
        <h1 className="inline-block text-3xl text-center mb-3 pb-1 text-blue-900 uppercase font-bold mx-auto">
          Road Map 
        </h1>
        <div className='flex justify-center'>
          <div className='w-24 border-b-4 border-blue-900 mb-8'></div>
        </div>
      </div>

    <div className="flex flex-wrap justify-center gap-4">
      {/* Card 1 */}
      <div className="max-w-sm bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transform hover:scale-105 transition duration-300">
        <div className="px-6 py-4">
          <div className="font-bold text-xl text-indigo-500 mb-2">#1</div>
          <p className="text-gray-700 text-base">
          User-Centric Design: Implement user experience (UX) best practices to create intuitive and engaging interfaces.
          </p>
        </div>
      </div>

      {/* Card 2 */}
      <div className="max-w-sm bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transform hover:scale-105 transition duration-300">
        <div className="px-6 py-4">
          <div className="font-bold text-xl text-indigo-500 mb-2">#2</div>
          <p className="text-gray-700 text-base">
          Agile Development: Adopt Agile methodologies to facilitate iterative development and adapt to changing requirements.
          </p>
        </div>
      </div>
      <div className="max-w-sm bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transform hover:scale-105 transition duration-300">
        <div className="px-6 py-4">
          <div className="font-bold text-xl text-indigo-500 mb-2">#2</div>
          <p className="text-gray-700 text-base">
          Frontend Development: Develop frontend components using modern technologies such as React, Angular, or Vue.js.
          </p>
        </div>
      </div>
      <div className="max-w-sm bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transform hover:scale-105 transition duration-300">
        <div className="px-6 py-4">
          <div className="font-bold text-xl text-indigo-500 mb-2">#2</div>
          <p className="text-gray-700 text-base">
          Backend Development: Build scalable and secure backend systems using frameworks like Node.js, Django, or Laravel.
          </p>
        </div>
      </div>
      <div className="max-w-sm bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transform hover:scale-105 transition duration-300">
        <div className="px-6 py-4">
          <div className="font-bold text-xl text-indigo-500 mb-2">#2</div>
          <p className="text-gray-700 text-base">
          Testing and QA: Conduct rigorous testing and quality assurance to ensure software reliability, performance, and security.
          </p>
        </div>
      </div>

      {/* More Cards... */}

      {/* Card 6 */}
      <div className="max-w-sm bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transform hover:scale-105 transition duration-300">
        <div className="px-6 py-4">
          <div className="font-bold text-xl text-indigo-500 mb-2">#6</div>
          <p className="text-gray-700 text-base">
            Iterative delivery. We divide the implementation process into several checkpoints rather than a single deadline.
          </p>
        </div>
      </div>
    </div>
    </>
    
  );
};

export default Devstage;
