import React, { useState } from "react";
import { AiFillLayout } from "react-icons/ai";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { MdSecurity } from "react-icons/md";

const ServicesBox = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const Services = [
    {
      name: "UX research",
      description: "Delve into user insights to inform intuitive design and seamless experiences.",
      image: "https://picsum.photos/200/300",
      icon: <AiFillLayout className="text-4xl" />,
      bgColor: "bg-black ",
    },
    {
      name: "App Development",
      description: "Crafted mobile apps for iOS, Android, and cross-platform, ensuring seamless user experiences.",
      image: "https://picsum.photos/200/301",
      icon: <MdOutlinePhoneAndroid className="text-4xl" />,
      bgColor: "bg-blue-900 ",
    },
    {
      name: "Web App Development",
      description: "Built scalable and user-friendly web solutions tailored to your needs.",
      image: "https://picsum.photos/200/302",
      icon: <MdOutlinePhoneAndroid className="text-4xl" />,
      bgColor: "bg-blue-900   ",
    },
    {
      name: "Security",
      description: "Protect your digital assets with comprehensive security measures.",
      image: "https://picsum.photos/200/303",
      icon: <MdSecurity className="text-4xl" />,
      bgColor: "bg-black ",
    },
    {
      name: "Hosting",
      description: "Reliable hosting services for seamless access to your applications.",
      image: "https://picsum.photos/200/303",
      icon: <MdSecurity className="text-4xl" />,
      bgColor: "bg-black",
    },
    {
      name: "IT solutions ",
      description: "Customized solutions for your infrastructure and software needs.",
      image: "https://picsum.photos/200/303",
      icon: <MdSecurity className="text-4xl" />,
      bgColor: "bg-blue-900 ",
    },
  ];

  return (
    <section id="services" className="my-10  container  mx-auto">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
        {Services.map(({ name, description, image, icon, bgColor }) => (
          <div
            key={name}
            className={`${bgColor} rounded-xl  text-white bg-cover bg-no-repeat bg-center bg-blend-overlay transition-transform duration-300 ${
              isHovered ? "transform hover:scale-105" : ""
            }`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="p-3 md:p-16 backdrop-blur-sm space-y-3 rounded-xl">
              {icon}
              <h1 className="text-2xl font-bold">{name}</h1>
              <p>{description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

const Services = () => {
  return (
    <section
      className="bg-white dark:text-dark py-10"
      style={{ fontFamily: "Poppins, sans-serif" }}
    >
      <div className="container flex flex-col items-center mx-auto ">
        <h1 className="inline-block text-3xl text-center mb-3 pb-1 text-blue-900 uppercase font-bold mx-auto">
          Development Approach
        </h1>
        <div className="flex justify-center">
          <div className="w-24 border-b-4 border-blue-900 mb-8"></div>
        </div>
        <p className="text-slate-500 text-center md:w-[50%] mx-auto">
          Agile methodologies, user-centric design, and a commitment to quality
          assurance ensure we deliver exceptional software solutions tailored to
          your needs. Let's build something great together.
        </p>
      </div>
      <ServicesBox />
    </section>
  );
};

export default Services;
