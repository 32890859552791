import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  imageUrls: string[]; // Add imageUrls prop for multiple image URLs
}   
function TabPanel(props: TabPanelProps) {
  const { value, index, imageUrls } = props;
  return (
    
    
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >    
      {value === index && (
        <Box sx={{ p: 3 }}>
          {/* Render multiple images inside the TabPanel */}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            {imageUrls.map((imageUrl, idx) => (
              <img key={idx} src={imageUrl} alt={`Image ${idx}`} style={{ maxWidth: '5%', height: 'auto' }} />
            ))}
          </div>
        </Box>
      )}
    </div>
  );
}


export default function FullWidthTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const tabData = [
    { label: 'Frontend', imageUrls: ['https://static-00.iconduck.com/assets.00/javascript-js-icon-2048x2048-nyxvtvk0.png', 'https://cdn-icons-png.freepik.com/256/4943/4943029.png', 'https://cdn4.iconfinder.com/data/icons/iconsimple-programming/512/css-512.png', 'https://icons.veryicon.com/png/o/business/vscode-program-item-icon/typescript-def.png' ] },
    { label: 'Backend', imageUrls: ['https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Node.js_logo.svg/590px-Node.js_logo.svg.png', 'https://www.svgrepo.com/show/376344/python.svg', 'https://cdn-icons-png.flaticon.com/512/5968/5968332.png'] },
    { label: 'Database', imageUrls: ['https://cdn.iconscout.com/icon/free/png-256/free-sqlite-282687.png','https://logowik.com/content/uploads/images/aws-dynamodb5235.jpg', 'https://static-00.iconduck.com/assets.00/sql-database-sql-azure-icon-1955x2048-4pmty46t.png'] },
    // { label: 'CloudTesting', imageUrls: ['/path/to/image4_1.jpg', '/path/to/image4_2.jpg', '/path/to/image4_3.jpg'] },
  ];

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', bgcolor: 'background.paper', height: '40vh' }}>
      {/* Center the Tabs horizontally */}
      <Box sx={{ width: 800, flexGrow: 1 }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            {tabData.map((tab, idx) => (
              <Tab key={idx} label={tab.label} />
            ))}
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          {/* Render TabPanel for each tab */}
          {tabData.map((tab, idx) => (
            <TabPanel key={idx} value={value} index={idx} imageUrls={tab.imageUrls} />
          ))}
        </SwipeableViews>
      </Box>
    </Box>
  );
}
